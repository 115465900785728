<template>
  <div class="container">
    <van-row v-if="list.length" class="list">
      <van-col v-for="(item,index) in list" :key="index" span="24" @click="onClick(index)">
        <div class="list-header">
            {{ item.certificate_name }}
          </div>
        <div class="list-item van-hairline--bottom">
          <van-image class="list-item-img" :src="item.certificate_image" />
          <div class="list-item-inner">
            <div class="list-item-title">{{ item.certificate_no }}</div>
          </div>
        </div>
      </van-col>
    </van-row>
    <van-empty
      v-if="list.length == 0 && !searchLoading"
      :image="no_data"
      :description="noTips"
    />
    <loadings :loading='searchLoading' />
  </div>
  
</template>
<script>
import { ImagePreview, Empty } from 'vant'
import no_data from '@/assets/no_data.png'
import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'

export default {
  name: 'StudentCertificate',
  components: {
    [Empty.name]: Empty,
    loadings:Loadings,
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      searchLoading:false,
      noTips: '您还没有证书哦~',
      no_data
    }
  },
  created() {
    this.searchLoading = true
    this.$api.certificate_index().then(res => {
      this.list = res.data
      this.searchLoading = false
    })
  },
  methods: {
    onClick(index) {
      const item = this.list[index]
      WeixinJSBridge.invoke("imagePreview", {
          "urls":[item.certificate_image],
          "current": item.certificate_image
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .container{
    padding-top: 10px;
  }
    .list{
      padding: 10px 15px;
      &-header{
        padding: 5px 10px 6px;
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
      &-item{
          padding: 10px 15px;
          margin-bottom: 10px;
          background: #fff;
          overflow: hidden;
          border-radius: 4px;
          box-shadow: 0px 2px 4px 2px rgba(228, 228, 228, 0.5);
          &-img{
              width: 100%;
              // height: 210px;
          }
          &-inner{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 5px 10px;
          }
          &-title{
              font-size: 14px;
              color: #333;
          }
          &-txt{
              text-align: right;
              flex: 1;
              font-size: 12px;
              color: #666;
          }
      }
    }
</style>

